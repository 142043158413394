<script lang="ts" setup>
interface Props {
  errorMessages: string[];
  fontSize?: 'sm' | 'md' | 'lg';
}

const props = withDefaults(defineProps<Props>(), {
  fontSize: 'md',
});

const variantClasses = computed<string>(() =>
  twMerge(
    cva('text-red-500', {
      variants: {
        fontSize: {
          sm: 'text-[12px]',
          md: 'text-[14px]',
          lg: 'text-[16px]',
        },
      },
    })({
      fontSize: props.fontSize,
    }),
  ),
);
</script>

<template>
  <div>
    <div v-for="(message, i) in errorMessages" :key="`error-${i}`" :class="variantClasses">{{ message }}</div>
  </div>
</template>
